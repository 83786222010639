<template>
  <ApolloQuery
    :query="require('@/graphql/queries/Contact.gql')"
    :variables="{ id: $route.params.id, limit: $store.state.me.settings.lazy_load ? +this.limit: 0, skip: 0 }"
    @result="doneLoadingContact"
    class="lsg-page lsg-trade__page trade_page"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <div class="ct__row" v-if="error">
        <div class="ct__col">
          <div class="ct__input">
            <label for="message">Ошибка:</label>
            <input type="text" :value="error.message" id="message" disabled />
          </div>
        </div>
      </div>
      <div class="loading apollo" v-else-if="loading">Загрузка</div>
      <div class="lsg-trade" v-if="data && data.Contact">
        <div class="main__header">
          <div class="main__header__item left">
            <h1>{{ data.Contact.name || title }}</h1>
          </div>
          <div class="main__header__item"></div>
          <div class="main__header__item right"></div>
        </div>
        <div class="main__content">
          <div class="crm">
            <aside class="crm__aside">
              <div class="workspace_left__header">
                <div class="workspace_left__row">
                  <div class="workspace_left__item">
                    <ul class="workspace_left__tags">
                      <li
                        :key="index"
                        v-for="(tag, index) in data.Contact.tags"
                      >
                        {{ tag.name }}
                      </li>
                    </ul>
                  </div>
                  <div class="workspace_left__created">
                    <span>{{ getDate(data.Contact.createdAt) }}</span>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Contact.post">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Должность</span>
                      <span>{{ data.Contact.post }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Contact.birthday">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Дата рождения</span>
                      <span>{{
                        new Date(data.Contact.birthday).toLocaleDateString()
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="workspace_left__row"
                  v-for="phone in data.Contact.phone"
                  :key="phone"
                >
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Телефон</span>
                      <span>{{ phone }}</span>
                    </div>
                  </div>
                  <div class="workspace_left__item">
                    <i v-if="!callTimeout" @click="callPhone(phone)" style="cursor: pointer">
                      <PhoneOutIcon />
                    </i>
                    <i v-else style="cursor: wait">
                      X
                    </i>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Contact.email">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Email</span>
                      <span>{{ data.Contact.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row" v-if="data.Contact.city">
                  <div class="workspace_left__item">
                    <div class="workspace_left__budget">
                      <span>Город</span>
                      <span>{{ data.Contact.city }}</span>
                    </div>
                  </div>
                </div>
                <div class="workspace_left__row mb5">
                  <div class="workspace_left__item">
                    <span class="workspace_left__manager">{{
                      data.Contact.responsible_user.name
                    }}</span>
                  </div>
                  <div class="workspace_left__item">
                    <a
                      href="#"
                      class="settings"
                      @click.prevent="
                        showContactDataModal(
                          data.Contact.company._id || null,
                          data.Contact,
                          'update'
                        )
                      "
                    >
                      <NewSettingsIcon />
                    </a>
                  </div>
                </div>
              </div>
              <div class="workspace_left__row accordion">
                <div class="workspace_body__item accordion__item" v-if="data.Contact.company">
                  <h3
                    :class="{
                      trade_content__title: true,
                      active: company_dropdown,
                    }"
                    @click="company_dropdown = !company_dropdown"
                  >
                    <span>Организация</span>
                    <ArrowDown />
                  </h3>
                  <div class="accordion__content" v-if="company_dropdown">
                    <ul class="tc">
                      <li>
                        <ul class="tc__list">
                          <li>
                            <router-link
                              :to="{
                                name: 'company',
                                params: { id: data.Contact.company._id },
                              }"
                              class="tc__list__title"
                              >{{ data.Contact.company.name }}</router-link
                            >
                            <span class="tc__list__subtitle" v-if="data.Contact.company.inn"
                              >ИНН {{ data.Contact.company.inn }}</span
                            >
                            <i
                              @click="
                                showCompanyDataModal(
                                  $route.params.id,
                                  data.Contact.company,
                                  'Contact',
                                  null
                                )
                              "
                            >
                              <NewSettingsIcon />
                            </i>
                          </li>
                          <li v-if="data.Contact.company.phone.length">
                            <span class="tc__list__title">Телефон</span>
                            <span class="tc__list__subtitle">{{
                              data.Contact.company.phone[0]
                            }}</span>
                            <i v-if="!callTimeout" @click="callPhone(data.Contact.company.phone[0])">
                              <PhoneOutIcon />
                            </i>
                            <i v-else style="cursor: wait">
                              X
                            </i>
                          </li>
                          <li v-if="data.Contact.company.email">
                            <span class="tc__list__title">Почта</span>
                            <span class="tc__list__subtitle">{{
                              data.Contact.company.email
                            }}</span>
                            <i></i>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
            <LsgNotes
              v-if="data && data.Contact"
              :key="noteRerenderKey"
              :isRightPage="true"
              elementType="Contact"
              :elementId="data.Contact._id"
              :elementEmail="data.Contact.email"
              :notes="contactData.notes || []"
              :tasks="contactData.tasks || []"
              :activeTasks="contactData.activeTasks || []"
              :position="position1"
              :onLoading="onLoading"
              v-bind:position1.sync="position1"
            ></LsgNotes>
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import moment from 'moment'
import ArrowDown from '@/components/svg/arrow_down'
import LsgNotes from '@/components/ui/LsgNotes'
import NewSettingsIcon from '../../components/svg/new/Settings'
import PhoneOutIcon from '../../components/svg/new/PhoneOutIcon'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
// import GraphQLHelper from '@/helpers/GraphQLHelper'
// import axios from 'axios'

// const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Contact',

  props: ['id'],

  data: () => {
    return {
      title: 'ФИО контакта',
      links: [
        {
          name: 'Главная',
          route: 'home'
        },
        {
          name: 'Контакты',
          route: 'contacts'
        },
        {
          name: 'Редактирование контакта'
        }
      ],
      callTimeout: false,
      main_dropdown: false,
      company_dropdown: true,
      leftSwitcher: false,
      noteRerenderKey: 0,
      lastPosition: 0,
      position1: 1000000,
      contactData: null,
      onLoading: true,
      limit: 10
    }
  },

  components: {
    ArrowDown,
    LsgNotes,
    PhoneOutIcon,
    NewSettingsIcon
  },

  methods: {
    async doneLoadingContact ({ data }) {
      this.contactData = data.Contact
      if ((this.contactData._allNotesMeta + this.contactData.tasks.length) <= this.limit) this.onLoading = false
      if (this.onLoading) this.noteRerenderKey++
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    showContactDataModal (companyId, data, type) {
      this.$store.state.data.trades.contactDataModal = !this.$store.state.data
        .trades.contactDataModal
      this.$store.state.data.trades.contactDataModalProps.companyId = companyId
      this.$store.state.data.trades.contactDataModalProps.data = data
      this.$store.state.data.trades.contactDataModalProps.type = type
    },
    showCompanyDataModal (elementId, data, elementType, isCanceled) {
      this.$store.state.data.trades.companyDataModal = !this.$store.state.data
        .trades.companyDataModal
      this.$store.state.data.trades.companyDataModalProps.companyId = elementId
      this.$store.state.data.trades.companyDataModalProps.data = data
      this.$store.state.data.trades.companyDataModalProps.type = elementType
      this.$store.state.data.trades.companyDataModalProps.type = isCanceled
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)

      // await axios.get('https://192.168.10.3/amocrm/amocrm.php', {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Credentials': true
      //   },
      //   // httpsAgent: new https.Agent({
      //   //   rejectUnauthorized: false
      //   // }),
      //   timeout: 1000,
      //   params: {
      //     _login: 'amocrm',
      //     _secret: 'mWYd1BU9hmWuezov',
      //     _action: 'call',
      //     to: phone,
      //     as: 'Amocrm',
      //     from: this.$store.state.me.workphone,
      //     rand: Math.random()
      //   }
      // })
      //   .then(res => {
      //     if (JSON.parse(res.data.split('asterisk_cb(').join('').split(');').join('')).status === 'ok') {
      //       this.$notify({
      //         group: 'lsg-notify',
      //         text: 'Звонок успешно совершён'
      //       })
      //     } else {
      //       this.$notify({
      //         group: 'lsg-notify',
      //         text: res.data
      //       })
      //       // throw new Error(res.data)
      //     }
      //     return res
      //   })
      //   .catch(err => {
      //     this.$notify({
      //       group: 'lsg-notify',
      //       text: err
      //     })
      //     // console.error(`Ошибка в исходящем звонке ${user.workphone} -> ${phone}`, err.message)
      //     // sendInTelegram(`Опаньки. А звоночек-то не прошёл ${user.workphone} -> ${phone}\n<i>${err}</i>`)
      //     throw new Error(err)
      //   })
      // if (this.callTimeout) {
      //   this.$notify({
      //     group: 'lsg-notify',
      //     text: 'Ожидайте 20 секунд перед новым звонком'
      //   })
      //   return
      // }
      // this.$apollo
      //   .mutate({
      //     mutation: MAKE_CALL,
      //     variables: {
      //       phone
      //     },
      //     update: (_, { data }) => {
      //       if (data) {
      //         this.$notify({
      //           group: 'lsg-notify',
      //           text: 'Звонок успешно совершён'
      //         })
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     _graphQlHelper.graphQLErrorMessages(error).forEach((item) => {
      //       this.$notify({
      //         group: 'lsg-notify',
      //         text: item
      //       })
      //     })
      //   })

      // this.callTimeout = true
      // setTimeout(() => {
      //   this.callTimeout = false
      // }, 20_000)
    }
  },
  watch: {
    position1: function () {
      this.limit = this.limit + 10
    }
  }
}
</script>

<style lang="stylus">
.modal__attention__textarea > textarea {
  max-width 300px
}

.modal__attention__origin {
  display grid
  grid-template-columns 1fr 1fr
  grid-column-gap 10px
  grid-row-gap 10px
  width 100%
  margin-bottom 20px

  +below(768px) {
    grid-template-columns 1fr
  }
}

.modal__origin__item {
  display flex
  align-items center
  flex-wrap nowrap
}

.modal__origin__checkbox {
  margin-right 10px
  max-width 15px
  max-height 15px
}

.modal__origin__label {
  display inline-block
  white-space nowrap
  color black
}

.item__switch {
  display flex
  flex-direction row
  flex-wrap wrap
  justify-content center
  align-items center
  margin 0 10px

  > .switcher {
    margin-bottom -5px
    margin-right 10px
  }

  > span {
    align-text center

    +below(768px) {
      display none
    }
  }
}

.lsg-trade {
  width: 100%;
}

.crm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -16px;
  width: 100%;

  +below(768px) {
    width: calc(100% + 42px);
  }

  +below(560px) {
    flex-direction: column;
  }

  +below(540px) {
    margin-bottom: 60px;
  }

  &__div {
    margin: 0 4px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    height: calc(100vh - 195px);
    margin: 0 16px;
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;

    +below(768px) {
      max-width: 50%;
      margin-right: 0;
    }

    +below(560px) {
      max-width: 100%;
      height: auto;
      margin: 0 16px;
      box-sizing: border-box;
      width: auto;
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: calc(100% - 420px);
    width: 100%;
    margin: 0 -16px;
    position: relative;
    padding: 0 11px;

    +below(768px) {
      max-width: 50%;
      margin: 0;
      padding: 0;
    }

    +below(560px) {
      max-width: 100%;
    }

    &__overdue {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 32px;
      absolute: left top;
      width calc(100% - 28px)
      background: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transform: translateY(-100%);
      margin: 0 14px;
      border-bottom: 1px solid $darkgray;
      box-shadow: 0 7px 30px rgba($black, 0.06);
      z-index: 1;

      +below(560px) {
        display: none;
      }

      &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__div {
        margin-left: 20px;
        margin-right: 5px;
      }

      &__list {
        padding: 0;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $c7A;
        margin: -4px 0 -4px 20px;

        li {
          margin: 4px 0;
        }
      }
    }

    &__send {
      background: none;
      border: none;
      absolute: right 16px bottom 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__drag {
      background: none;
      border: none;
      absolute: right 60px bottom 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 !important
    }

    &__cancel {
      absolute: right 64px bottom 19px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $blue;
    }

    &__div {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $c4E555A;
    }

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: calc(100vh - 350px);
      overflow: hidden;
      padding: 0 16px 200px;
      margin-bottom: 8px;

      +below(560px) {
        height: auto;
        margin-top: 24px;
        padding-bottom: 10px;
      }
    }

    &__form {
      background: $white;
      border: 1px solid $darkgray;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0 16px;
      position: relative;

      &--deminished {
        margin: 0;
        border: 0;

        .mx-input {
          background: transparent !important;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 14px;
        // height 1rem

        & .mx-input-wrapper input {
          background: transparent !important;
        }
      }

      &__body {
        textarea {
          border: none;
          resize: none;
          height: 100%;
          display: flex;
          border-radius: 0 0 10px 10px;
        }
      }

      select {
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        width: fit-content !important;
        appearance: none;
        background: none;
        padding: 6px 8px;
        height: auto;
        box-shadow: none !important;
        outline: none !important;
      }

      .mx-datepicker {
        width: auto;
      }

      .mx-input {
        border: none !important;
        height: auto !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        color: $blue !important;
        width: fit-content !important;
        box-shadow: none !important;
      }

      .vs--single.vs--open {
        .vs__selected {
          position: relative;
        }
      }

      .v-select {
        width: fit-content;

        .vs__dropdown-toggle {
          padding: 0;
          border: none;
        }

        .vs__selected {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $blue;
        }

        .vs__search {
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
    }

    &__type {
      display: inline-flex;
      position: relative;
      user-select: none;

      &__selected {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        padding: 6px 8px;
        cursor: pointer;
      }

      &__list {
        absolute: left bottom;
        padding: 0;
        transform: translateY(100%);
        background: $white;
        border: 1px solid $pinky;
        box-sizing: border-box;
        box-shadow: 0 10px 30px rgba($c4E555A, 0.17);
        border-radius: 0 0 10px 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        z-index: 999;
        overflow: hidden;

        li {
          padding: 6px 8px;
          width: 100%;
          color: $c4E555A;
          background: $white;
          cursor: pointer;

          &:hover {
            background: $orange;
            color: $white;
          }
        }
      }
    }
  }

  &__note {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 8px;
    flex-shrink: 0;

    &__close-outdated {
      display: flex;

      button {
        margin-left: 20px;
      }
    }

    &--red {
      background: rgba($red, 0.1);
      border: 1px solid rgba($darkgray, 0.3);
      width: calc(100% - 28px);
      margin: 10px auto 8px;

      .crm__note__date, .crm__note__manager, .crm__div, .crm__note__responsible {
        color: $red;
        font-weight: bold;
      }

      .crm__note__icon svg {
        path[stroke] {
          stroke: $red;
        }

        path[fill] {
          fill: $red;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;
      padding-bottom: 10px;
      border-bottom: 1px solid $pinky;
      margin-bottom: 10px;

      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__actions {
        display: flex;
        align-items: center;
        margin: 0 -6px;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      padding: 0 6px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      svg {
        width: 100%;
        height: 100%;

        path[stroke] {
          stroke: $orange;
        }

        path[fill] {
          fill: $orange;
        }
      }

      &--green {
        svg {
          path[stroke] {
            stroke: $darkgreen;
          }

          path[fill] {
            fill: $darkgreen;
          }
        }
      }
    }

    &__date {
      margin-right: 15px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      font-size: 14px;

      span, strong, p {
        font-size: 14px;
      }

      p .crm__note__text {
        margin-right: 4px;
      }
    }

    &__text {
      font-size: 0.9em;
      line-height: normal;
    }

    &__footer {
      padding-top: 10px;
      border-top: 1px solid $pinky;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;

      .btn {
        padding: 6px 8px;
        min-height: auto;
        margin: 0 16px;
        border-radius: 5px;
      }
    }
  }
}
button[disabled] {
  border 1px solid #999999
  background-color #cccccc
  color #666666
  pointer-events none
}
.lead-rating, .lead-accreditation, .lead-liquidity {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-left: 10px;

    /* padding: 5px; */
  > svg {
    height: inherit;
    width: inherit;
    color: #231F20;
    fill: #231F20;

    path[stroke-width] {
      stroke-width 5
    }

    path[stroke] {
      stroke #231F20
    }

    path[fill] {
      fill #231F20
    }
  }
}
.lead-rating--green, .lead-accreditation--green, .lead-liquidity--green {
  > svg {
    color: #219653;
    fill: #219653;
  }
}
.lead-accreditation--red, .lead-rating--red, .lead-liquidity--red {
  > svg {
    color: #FF0000;
    fill: #FF0000;
  }
}
.lead-accreditation--yellow, .lead-rating--yellow, .lead-liquidity--yellow {
  > svg {
    color: #FF8000;
    fill: #FF8000;
  }
}
.guarantor-icon {
  svg {
    path[stroke] {
      stroke $black !important
    }

    path[fill] {
      fill $black !important
    }
  }
}
</style>
